import Invoice from '@views/main/management/invoice/Datatable';
import Logs from '@views/main/management/logs/Datatable';
import Sales from '@views/main/management/sales/Datatable';
import Profits from '@views/main/management/profits/Datatable';
import WorkingReport from '@views/main/management/working_report/Datatable';
import PM from '@views/main/management/pm/Datatable';
import WorkingHour from '@views/main/management/working_hour/Datatable';
import Combined from '@views/main/management/combined/Datatable';

import constants from "@/const/const";

const managementRoutes = [
  {
    path: 'management/invoices',
    name: 'management.invoices',
    component: Invoice,
    meta: {
        //only Japan team can view(HQ)
        restricted: constants.permissions.nonHQ
      },
  },
  {
    path: 'management/logs',
    name: 'management.logs',
    component: Logs,
    meta: {
        //only Japan team can view(HQ)
        restricted: constants.permissions.nonHQ
      },
  },
  {
    path: 'management/sales',
    name: 'management.sales',
    component: Sales,
    meta: {
        //only Japan team can view(HQ)
        restricted: constants.permissions.nonHQ
      },
  },
  {
    path: 'management/profits',
    name: 'management.profits',
    component: Profits,
    meta: {
      editPage: true,
      //only Japan team can view(HQ)
      restricted: constants.permissions.nonHQ
    }
  },
  {
    path: 'management/workingreport',
    name: 'management.working_report',
    component: WorkingReport,
    meta: {
        //only Japan team can view(HQ)
        restricted: [constants.permissions.restrictedUserString]
      },
  },
  {
    path: 'management/pms',
    name: 'management.pms',
    component: PM,
    meta: {
      editPage: true,
      //only Japan team can view(HQ)
      restricted: constants.permissions.nonHQ
    }
  },
  {
    path: 'management/workinghours',
    name: 'management.working_hours',
    component: WorkingHour,
    meta: {
        //only Japan team can view(HQ)
        restricted: constants.permissions.nonHQ
      },
  },
  {
    path: 'management/combined',
    name: 'management.combined',
    component: Combined,
    meta: {
      //only Japan team can view(HQ)
      restricted: constants.permissions.nonHQ
    },
  }
]

export default managementRoutes;