/*
 * [Vuei18n feature] In vue file, you can refer from like $t('general.auth.email')
 *     - Defined in node_modules/vue-i18n/types/index.d.ts > VueI18n class
 */
const en = {
  localeLabel: 'English',
  general: {
    auth: {
      welcome: 'Welcome',
      signInMessage: 'Sign in to your account',
      email: 'Email',
      username: 'Username',
      password: 'Password',
      showPassword: 'Show Password',
      rememberMe: 'Remember Me',
      login: 'Login',
      adminLogin: 'Admin Login',
      logout: 'Logout',
      register: 'Register',
    },
    user: {
      firstName: 'First Name',
      lastName: 'Last Name',
      fullName: 'Fullname',
      profPic: 'Profile Picture',
    },
    customer: {
      customer: 'Customer',
      list: {
        pageTitle: 'Customers - List',
        metaTitle: 'Customers - List',
      },
      edit: {
        pageTitle: 'Customers - Edit',
        metaTitle: 'Customers - Edit',
      },
      create: {
        pageTitle: 'Customers - Create New',
        metaTitle: 'Customers - Create New',
      },
      message: {
        nameRequired: 'Customer is required',
      },
    },
    project: {
      type: 'Type',
      name: 'Project Name',
      customer: 'Customer',
      color: 'Color',
      customerValidation: 'Please select an existing customer',
      projectCreate: 'Projects - Create New',
      projectEdit: 'Projects - Edit',
      list: {
        pageTitle: 'Projects - List',
        metaTitle: 'Projects - List',
      },
      edit: {
        pageTitle: 'Projects - Edit',
        metaTitle: 'Projects - Edit',
      },
      create: {
        pageTitle: 'Projects - Create New',
        metaTitle: 'Projects - Create New',
      },
      projectNameValidation: 'Please write a project name',
      projectNameMessage: 'Please always include English',
      typeValidation: 'Please select an existing type',
    },
    member: {
      list: {
        pageTitle: 'Members - List',
        metaTitle: 'Members - List',
      },
      create: {
        pageTitle: 'Members - Create New',
        metaTitle: 'Members - Create New',
      },
      edit: {
        pageTitle: 'Members - Edit',
        metaTitle: 'Members - Edit',
      },
      team: 'Team',
      name: 'Display Name',
      username: 'Username',
      password: 'Password',
      loginInfo: 'Login Information',
      defaultPasswordButton: 'Edit',
      pressedPasswordButton: 'Cancel',
      role: 'Role',
      permission: 'Permission',
      status: 'Status',
      submit: 'Submit',
      message: {
        teamRequired: 'Team is required',
        nameRequired: 'Name is required',
        nameMaxCharacters: 'Name must be less than 25 characters',
        nameDuplicateWarning: 'This name is already exists',
        usernameRequired: 'Username is required',
        usernameMinCharacters: 'Username must be greater than 4 characters',
        usernameMaxCharacters: 'Username must be less than 25 characters',
        usernameAlphanumeric: 'Username must contain alphanumeric characters or .-_',
        usernameDuplicate: 'This username is already taken',
        passwordRequired: 'Password is required',
        passwordMinCharacters: 'Password must be greater than 12 characters',
        passwordMaxCharacters: 'Password must be less than 25 characters',
        passwordAlphanumeric: 'Password must be alphanumeric characters',
        passwordLowerCase: 'Password must contain lower case characters',
        passwordUpperCase: 'Password must contain upper case characters',
        passwordNumber: 'Password must contain at least one number',
        passwordSpecialCharacter: 'Password must contain at least one special character',
        roleRequired: 'Role is required',
        permissionRequired: 'Permission is required',
        duplicateChecking: 'Checking for duplicate...',
      },
    },
    modificationHistory: {
      list: {
        pageTitle: 'Modification History',
      },
      date: 'Date',
      type: 'Type',
      beforeUpdate: 'before update',
      afterUpdate: 'after update',
      project: 'Project',
      order: 'Order',
      pm: 'PM',
      updatedBy: 'Update by',
      invoiceAmount: 'Invoice Amount',
      InvoiceDate: 'Invoice Date',
    },
    role: {
      role: 'Role',
      admin: 'Administrator',
      user: 'User',
    },
    invoice: {
      list: {
        pageTitle: 'Management - Order/Invoices',
        metaTitle: 'Management - Order/Invoices',
      },
      name: 'Order Name',
      pm: 'PM',
      member: 'Project Members',
      status: 'Order Status',
      all: 'All',
      invoiceMonth: 'Month of Invoicing',
      paymentDeadlineMonth: 'Month of Payment Deadline',
      paymentAmount: 'Invoice Amount (before tax)',
      amount: 'Amount without tax',
      acceptanceDate: 'Date of Acceptance',
      invoiceDate: 'Invoice Date',
      paymentDeadline: 'Payment Deadline',
      paymentDate: 'Payment Date',
      yen: 'Yen',
      purchaseOrderUrl: 'Purchase Order',
      invoiceUrl: 'Invoice',
      amountTax: 'Amount with tax',
    },
    projectReport: {
      list: {
        pageTitle: 'Management - Profit',
        metaTitle: 'Management - Profit',
      },
      profit: 'Profit',
      profitRate: 'Percentage Profit',
      indCost: 'Indonesian Labour Cost',
      japanCost: 'Japanese Labour Cost',
      outsourcingCost: 'Outsourcing Cost',
    },
    PMS: {
      list: {
        pageTitle: 'Management - PMs',
        metaTitle: 'Management - PMs',
      },
      term: 'Term',
      pm: 'PM',
      sales: 'Sales',
      cost: 'Cost',
      profit: 'Profit',
    },
    assignmentSheet: {
      project: 'Project',
      member: 'Member',
      teams: 'Team',
      dialogAdd: {
        title: 'Assignment Add',
        startDate: 'Start Date',
        endDate: 'End Date',
        workingHourPerDay: 'working hour per day',
        weekend: 'Weekend',
        note: 'description',
        validation: {
          required: 'This field is required',
        },
      },
      schedules: 'Schedules',
      assignments: 'Assignments',
      dailyReports: 'Daily Reports',
    },
    schedule: {
      createDailyReport: 'Create Daily Report',
      cancel: 'Cancel',
      addEvent: 'Add Event',
      editEvent: 'Edit Event',
      title: 'Title*',
      project: 'Project',
      order: 'Order',
      description: 'Description',
      close: 'CLOSE',
      keep: 'KEEP',
      update: 'UPDATE',
      allDay: 'All Day',
      member: 'Member',
    },
    validation: {
      required: 'Required',
      optional: 'Optional',
      confirm: 'Confirm',
      cancel: 'Cancel',
      projectExists: 'This project already exists',
      dailyReportExists: 'A daily report already created on this date',
      noEventExists: 'There are no events on this date',
    },
    demo: {
      dummyMeetings: 'Dummy Meetings',
    },
    filter: {
      reset: 'Reset Form',
      user: 'User',
      customer: 'Customer Name',
      name: 'Full Name',
      noItem: 'There is no data available',
    },
    form: {
      reset: 'Reset Form',
      checkboxReset: 'Deselect All',
      search: 'Search',
    },
    crud: {
      createNew: 'Create New',
      list: 'List',
      add: 'Add New',
      edit: 'Edit',
      update: 'Update',
      change: 'Change',
      delete: 'Delete',
      search: 'Search',
      toggleTotals: {
        header: 'Show/Hide Totals',
        label: 'Toggle Totals',
      },
      loading: 'Loading...',
      all: 'All',
      minPlaceholder: 'No Lower Limit',
      maxPlaceholder: 'No Upper Limit',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      action: 'Action',
      total: 'Total',
      filterColumn: 'Hide Columns',
      btn: {
        edit: 'Edit',
        duplicate: 'Duplicate',
        delete: 'DELETE',
      },
      reset: 'Reset',
      editMode: 'Edit Mode',
    },
    time: {
      date: 'Date',
      time: 'Time',
    },
    nav: {
      projectName: 'PM-Tool',
      grune: 'Grune',
      dashboard: 'Dashboard',
      users: 'Users',
      dailyReports: 'Daily Reports',
      orders: 'Orders',
      management: {
        management: 'Management',
        modificationHistory: 'Modification Logs',
        projects: 'Projects',
        profits: 'Profits',
        combined: 'Combined',
      },
      invoices: 'Orders/Invoices',
      sales: 'Sales',
      workingReports: 'Working Reports',
      pms: 'PM Reports',
      members: 'Members',
      customers: 'Customers',
      projects: 'Projects',
      unitPrices: 'Unit Prices',
      workingHours: 'Working Hours',
      assignmentSheets: 'Assignment Sheet',
      schedules: 'Schedule',
    },
    placeholder: {
      email: 'sample@email.com',
      username: 'example_665',
      firstName: 'Abdul',
      lastName: 'Ali',
      fullName: 'Abdul Ali',
    },
    alert: {
      youSure: 'Are you sure?',
      confirmDelete: 'Once the item is deleted, we cannot get it back for you.',
      selectDate: 'Please select a day',
    },
    order: {
      contract: 'Contract',
      labo: 'Labo',
      is_active: 'Active Status',
      active: 'Active',
      unactive: 'Inactive',
      projects: 'Project',
      projectName: 'Project Name',
      type: 'Type',
      customer: 'Customer',
      orders: 'Order',
      offshore: 'Offshore',
      orderName: 'Order Name',
      jp: 'JP',
      en: 'EN',
      status: 'Order Status',
      members: 'Members',
      memberJpn: 'JP',
      memberIdn: 'IDN',
      pm: 'PM：',
      invoicingInfomation: 'Invoicing Information',
      invoiceAmount: 'Price (Before Tax)',
      invoiceAmountWithTax: 'Price (With Tax)',
      manuallyInputPriceWithTax: 'Manually Input Price With Tax',
      invoiceAmountWithTaxWarning: 'The price with tax must match the Freee invoice. Please enter manually if it does not match exactly.',
      yen: '円',
      invoiceDate: 'Invoice Date',
      paymentDeadline: 'Payment Deadline',
      paymentDate: 'Payment Date',
      purchaseOrderUrl: 'Purchase Order URL',
      invoiceUrl: 'Invoice URL',
      outsourcingCost: 'Outsourcing Cost',
      itemName: 'Item Name',
      paymentAmount: 'Amount (Before Tax)',
      add: 'Add',
      remarks: 'Remarks',
      submit: 'Submit',
      edit: {
        pageTitle: 'Orders/Invoices - Edit',
        metaTitle: 'Orders/Invoices - Edit',
      },
      create: {
        pageTitle: 'Orders/Invoices - Create New',
        metaTitle: 'Orders/Invoices - Create New',
      },
      message: {
        projectNameRequired: 'Project name is required',
        jpRequired: 'Order name (JP) is required',
        enRequired: 'Order name (JP) is required',
        statusRequired: 'Status is required',
        acceptedDateRequired: 'Accepted Date is required',
        pmRequired: 'PM is required',
        invoiceAmountRequired: 'Price (Before Tax) is required',
        invoiceDateRequired: 'Invoice date is required',
        paymentDeadlineRequired: 'Payment Deadline is required',
        purchaseOrderUrlRequired: 'Purchase order URL is required',
        invoiceUrlRequired: 'Invoice URL is required',
        itemNameRequired: 'Item name is required',
        paymentAmountRequired: 'Payment Amount is required',
        paymentDateRequired: 'Payment Date is required',
        jpMinCharacters: 'Order name (JP) must be greater than 3 characters',
        enMinCharacters: 'Order name (EN) must be greater than 8 characters',
        itemNameMinCharacters: 'Item name must be greater than 1 characters',
        invoiceAmountMinCharacters: 'Invoice Amount must be greater than 1 yen',
        purchaseOrderUrlCheck: 'Purchase Order URL has to be URL',
        invoiceUrlCheck: 'Invoice URL has to be URL',
        remarksMaxCharacters: 'Remarks must be less than 510 characters',
        duplicateMember: 'Duplicate Member, please select another member',
        projectNameCheck: 'Please select a project',
      },
      issuer: 'Issuer',
      officeWork: 'Office work',
    },
    unit_price: {
      pageTitle: 'Unit Prices - List',
      team: 'Team',
      role: 'Role',
      price: 'Price',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
    },
    reports: {
      remaining: ' characters remaining',
      saveDraft: 'Save Draft',
      loadDraft: 'Load Draft',
      list: {
        pageTitle: 'Daily Report - List',
        metaTitle: 'Daily Report - List',
      },
      create: {
        pageTitle: 'Daily Report - Create New',
        metaTitle: 'Daily Report - Create New',
      },
      edit: {
        pageTitle: 'Daily Report - Edit',
        metaTitle: 'Daily Report - Edit',
      },
      add: 'add',
      condition: 'Search Condition',
      date: 'Date',
      projectName: 'Project Name',
      orderName: 'Order',
      JP: 'JP',
      EN: 'EN',
      result: 'Search Result',
      total: 'Total Time[H]',
      time: 'Time[H]',
      progress: 'Progress',
      message: {
        progressLength: 'Please input less than 2000 characters',
        start_date: {
          greaterThanToday: 'This date must be less than or equal to Today',
          greaterThanDateEnd: 'This date must be less than or equal to start date of this filter',
        },
        end_date: {
          greaterThanToday: 'This date must be less than or equal to Today',
          lessThanDateStart: 'This date must be greater than or equal to start date of this filter',
        },
        dateRequired: 'Date is required',
        dateLesserThanToday: 'This date must be lesser than or equal to Today',
        projectNameRequired: 'Project Name is required',
        orderNameJPRequired: 'Order Name(JP) is required',
        orderNameENRequired: 'Order Name(EN) is required',
        timeRequired: 'Time in hours is required',
        progressRequired: 'Progress is required',
      },
      projectNotAvailableGuidance:
        "* if you cannot find your project, please contact the project's PM and ask to be added to the current order.",
      totalWorkingTime: 'Total Working Time',
      close: 'Close',
      save: 'Save',
      addOrder: 'Order - Add',
      newProjectName: 'Project',
      newOrderName: 'Order',
      addNewOrder: 'Add New Order',
    },
    sales: {
      list: {
        pageTitle: 'Management - Sales',
        metaTitle: 'Management - Sales',
      },
      placeholder: {
        lowerMonth: 'From',
        upperMonth: 'Until',
      },
      status: 'Invoice Status',
      all: 'Select All',
      invoiceMonth: 'Term',
      amount: 'Sales',
      amountPotentialHigh: 'Sales+Potential Order(high)',
      amountPotentialModerate: 'Sales+Potential Order(med)',
      amountPotentialLow: 'Sales+Potential Order(low)',
      acceptanceDate: 'Date of Acceptance',
      yen: ' Yen',
    },
    workingreport: {
      list: {
        pageTitle: 'Management - Working Report',
        metaTitle: 'Management - Working Report',
      },
      placeholder: {
        lowerMonth: 'From',
        upperMonth: 'Until',
      },
      term: 'Term',
      team: 'Team',
      name: 'Name',
      role: 'Role',
      time: 'Time[H]',
      detail: 'Detail',
    },
    workingHour: {
      jpnHour: 'Japanese Worktime',
      idnHour: 'Indonesia Worktime',
      totalCost: 'Total Cost',
      targetMonth: 'Target Month',
      list: {
        pageTitle: 'Management - Working Hours',
        metaTitle: 'Management - Working Hours',
      },
    },
    combined: {
      targetMonth: 'Working Hours Target Month',
      list: {
        pageTitle: 'Management - Combined',
        metaTitle: 'Management - Combined',
      },
    },
    tooltip: {
      noSelect: 'No Select',
    },
  },
};

export default en;
