<template>
  <div class="row-container">
    <tr class="table-row">
      <!-- Member name - Start -->
      <th scope="row" class="column-label text-left" :style="styles">
        {{ member.name }}
      </th>
      <!-- Member name - End -->

      <!-- Member total hour of each day - Start -->
      <TotalCell v-for="date in headers" :key="date" :date="date" :reports="memberReports" :member="true" />
      <!-- Member total hour of each day - Start -->
    </tr>

    <tr class="table-row" v-if="display.assignment">
      <th scope="row" class="column-label text-left" :style="styles" style="background-color: #FF4B4B;">
        {{ $t('general.assignmentSheet.assignments') }}
        <v-icon @click="openAssignmentDialog">mdi-plus-circle-outline</v-icon>
      </th>
      <!-- Member total hour of each day - Start -->
      <TotalCell v-for="date in headers" :key="date" :date="date" :reports="memberAssignments" :color="'#FF4B4B'" />
      <!-- Member total hour of each day - Start -->
    </tr>

    <!-- Member Assignment Rows -->
    <MemberAssignment
      v-for="project in projectsOfAssignment"
      :key="'A' + project.id"
      :index="projectOfAssignmentLastCount"
      :member="member"
      :project="project"
      :headers="headers"
      :assignments="memberAssignments"
      :color="project.color"
      :drawerOpen="drawerOpen"
      :display="display.assignment"
      :assignmentDialog="assignmentDialog"
      @assignment-form="handleAssignmentForm"
    />

    <!-- <MemberAssignment/> -->

    <tr class="table-row" v-if="display.schedule">
      <th scope="row" class="column-label text-left" :style="styles" style="background-color: #EED75C;">
        {{ $t('general.assignmentSheet.schedules') }}
      </th>
      <TotalCell v-for="date in headers" :key="date" :date="date" :reports="memberSchedules" :color="'#EED75C'" />
    </tr>

    <MemberSchedule
      v-for="project in projectsOfSchedule"
      :key="'S' + project.id"
      :index="projectLastCount"
      :member="member"
      :project="project"
      :headers="headers"
      :schedules="memberSchedules"
      :color="project.color"
      :drawerOpen="drawerOpen"
      :display="display.schedule"
    />

    <tr class="table-row" v-if="display.dailyReport">
      <th scope="row" class="column-label text-left" :style="styles" style="background-color: #4FEA36;">
        {{ $t('general.assignmentSheet.dailyReports') }}
      </th>
      <!-- Member total hour of each day - Start -->
      <TotalCell v-for="date in headers" :key="date" :date="date" :reports="memberReports" :color="'#4FEA36'" />
      <!-- Member total hour of each day - Start -->
    </tr>

    <!-- Member project rows -->
    <MemberReport
      v-for="project in projects"
      :key="'R' + project.id"
      :index="projectLastCount"
      :member="member"
      :project="project"
      :headers="headers"
      :reports="memberReports"
      :color="project.color"
      :drawerOpen="drawerOpen"
      :display="display.dailyReport"
    />
  </div>
</template>

<script>
import { filter } from 'lodash';
import colors from '../../colors';
import TotalCell from './member/TotalCell';
import MemberReport from './Report';
import MemberAssignment from './Assignment';
import MemberSchedule from './Schedule';

export default {
  components: {
    TotalCell,
    MemberReport,
    MemberAssignment,
    MemberSchedule,
  },

  data: () => ({
    colors,
  }),

  props: {
    member: { type: Object, default: () => {} },
    headers: { type: Array, default: () => [] },
    projects: { type: Array, default: () => [] },
    projectsOfSchedule: { type: Array, default: () => [] },
    projectsOfAssignment: { type: Array, default: () => [] },
    reports: { type: Array, default: () => [] },
    schedules: { type: Array, default: () => [] },
    assignments: { type: Array, default: () => [] },
    drawerOpen: { type: Boolean, default: true },
    display: { type: Object },
    assignmentDialog: { type: Function, default: () => {} },
  },

  computed: {
    // Schedules filtered by member
    memberSchedules() {
      const member = this.member;
      return filter(this.schedules, function(schedule) {
        return schedule.member_id === member.id;
      });
    },
    // Reports filtered by member
    memberReports() {
      const member = this.member;
      return filter(this.reports, function(report) {
        return report.member_id === member.id;
      });
    },
    // Get project last count index
    projectLastCount() {
      return this.projects[this.projects.length - 1];
    },
    // Get project of assignment last count index
    projectOfAssignmentLastCount() {
      return this.projectsOfAssignment[this.projectsOfAssignment.length - 1];
    },

    styles() {
      return {
        left: this.drawerOpen == true ? '0' : '0',
      };
    },

    // Assignments filtered by member
    memberAssignments() {
      const member = this.member;
      return filter(this.assignments, function(assignment) {
        return assignment.member_id === member.id;
      });
    },
  },

  methods: {
    openAssignmentDialog() {
      this.$props.assignmentDialog(this.member.id);
    },
    handleAssignmentForm(assignmentForm) {
      // Pass the assignmentForm values up to the Table component
      this.$emit('assignment-form', assignmentForm);
    },
  },
};
</script>
