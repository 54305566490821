<template>
  <v-navigation-drawer v-model="drawerOpen" app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ $t('general.nav.projectName') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('general.nav.grune') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item-group>
        <template v-for="menu in navLeftMenus">
          <!-- check if the nav bar element is restricted or not -->
          <div v-if="!menu.restricted.includes(memberRole)" :key="menu.id">
            <!-- Show the items that are accessible from restricted admins only -->
            <!--if menu has not children -->
            <v-list-item v-if="!menu.hasChildren" :key="menu.id" :to="menu.route">
              <v-list-item-icon>
                <v-icon>{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(menu.label) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!--if menu has children -->
            <v-list-group v-else :key="menu.id">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ menu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(menu.label) }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <div v-for="submenu in menu.children" :key="submenu.title">
                <div v-if="!submenu.restricted.includes(memberRole)" :key="menu.id">
                  <v-list-item :to="submenu.route" exact>
                    <v-list-item-icon>
                      <v-icon>{{ submenu.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(submenu.label) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </v-list-group>
          </div>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import constants from '@/const/const';

const navLeftMenus = [
  // reports routes 
  {
    id: 'reports',
    label: 'general.nav.dailyReports',
    icon: 'mdi-newspaper-variant',
    hasChildren: true,
    restricted: [],
    children: [
      {
        id: 'reports.datatable',
        label: 'general.crud.list',
        icon: 'mdi-eye-outline',
        route: { name: 'reports' },
        restricted: [],
        hasChildren: false,
      },
      {
        id: 'reports.form',
        label: 'general.crud.createNew',
        icon: 'mdi-pencil-outline',
        route: { name: 'reports.add' },
        restricted: [],
        hasChildren: false,
      },
    ],
  },
  // projects routes
  {
    id: 'projects',
    label: 'general.nav.projects',
    icon: 'mdi-briefcase',
    restricted: [],
    hasChildren: true,
    children: [
      {
        id: 'projects.datatable',
        label: 'general.crud.list',
        icon: 'mdi-eye-outline',
        route: { name: 'projects' },
        restricted: [],
        hasChildren: false,
      },
      {
        id: 'projects.form',
        label: 'general.crud.createNew',
        icon: 'mdi-briefcase-plus-outline',
        route: { name: 'projects.create' },
        restricted: constants.permissions.nonHQ,
        hasChildren: false,
      },
    ],
  },
  // orders routes 
  {
    id: 'orders',
    label: 'general.nav.orders',
    icon: 'mdi-receipt',
    hasChildren: true,
    restricted: constants.permissions.nonHQ,
    children: [
      {
        id: 'orders.form',
        label: 'general.crud.createNew',
        icon: 'mdi-plus-circle-outline',
        route: { name: 'orders.create' },
        restricted: constants.permissions.nonHQ,
        hasChildren: false,
      },
    ],
  },
  // management routes 
  {
    id: 'management',
    label: 'general.nav.management.management',
    icon: 'mdi-account-supervisor',
    hasChildren: true,
    restricted: [constants.permissions.restrictedUserString],
    children: [
      {
        id: 'management.combined',
        label: 'general.nav.management.combined',
        icon: 'mdi-vector-combine',
        route: { name: 'management.combined'},
        restricted: constants.permissions.nonHQ,
        hasChildren: false,
      },
      {
        id: 'management.invoice',
        label: 'general.nav.invoices',
        icon: 'mdi-note-text-outline',
        route: { name: 'management.invoices' },
        restricted: constants.permissions.nonHQ,
        hasChildren: false,
      },
      {
        id: 'management.sales',
        label: 'general.nav.sales',
        icon: 'mdi-chart-line-variant',
        route: { name: 'management.sales' },
        restricted: constants.permissions.nonHQ,
        hasChildren: false,
      },
      {
        id: 'management.profits',
        label: 'general.nav.management.profits',
        icon: 'mdi-briefcase-outline',
        route: { name: 'management.profits' },
        restricted: constants.permissions.nonHQ,
        hasChildren: false,
      },
      {
        id: 'management.working_hours',
        label: 'general.nav.workingHours',
        icon: 'mdi-clock-time-four-outline',
        route: { name: 'management.working_hours' },
        restricted: constants.permissions.nonHQ,
        hasChildren: false,
      },
      {
        id: 'management.working_reports',
        label: 'general.nav.workingReports',
        icon: 'mdi-script-text-outline',
        route: { name: 'management.working_report' },
        restricted: [constants.permissions.restrictedUserString],
        hasChildren: false,
      },
      {
        id: 'management.pms',
        label: 'general.nav.pms',
        icon: 'mdi-piggy-bank-outline',
        route: { name: 'management.pms' },
        restricted: constants.permissions.nonHQ,
        hasChildren: false,
      },
      {
        id: 'management.modificationHistory',
        label: 'general.nav.management.modificationHistory',
        icon: 'mdi-book-clock-outline',
        route: { name: 'management.logs'},
        restricted: constants.permissions.nonHQ,
        hasChildren: false,
      },
    ],
  },
  //members routes
  {
    id: 'members',
    label: 'general.nav.members',
    icon: 'mdi-account-group',
    restricted: [constants.permissions.restrictedUserString],
    hasChildren: true,
    children: [
      {
        id: 'members.datatable',
        label: 'general.crud.list',
        icon: 'mdi-eye-outline',
        route: { name: 'members' },
        restricted: [constants.permissions.restrictedUserString],
        hasChildren: false,
      },
      {
        id: 'members.form',
        label: 'general.crud.createNew',
        icon: 'mdi-account-multiple-plus-outline',
        route: { name: 'members.create' },
        restricted: constants.permissions.exceptAdmin,
        hasChildren: false,
      },
    ],
  },
  //customer routes
  {
    id: 'customers',
    label: 'general.nav.customers',
    icon: 'mdi-account-tie',
    restricted: constants.permissions.nonHQ,
    hasChildren: true,
    children: [
      {
        id: 'customers.datatable',
        label: 'general.crud.list',
        icon: 'mdi-eye-outline',
        route: { name: 'customers' },
        restricted: constants.permissions.nonHQ,
        hasChildren: false,
      },
      {
        id: 'customers.form',
        label: 'general.crud.createNew',
        icon: 'mdi-account-multiple-plus-outline',
        route: { name: 'customers.create' },
        restricted: constants.permissions.exceptMainAdmin,
        hasChildren: false,
      },
    ],
  },
  //unit_price routes
  {
    id: 'unit_prices',
    label: 'general.nav.unitPrices',
    icon: 'mdi-circle-multiple',
    route: { name: 'unit_prices' },
    restricted: constants.permissions.nonHQ,
    hasChildren: false,
  },
  // assiments routes 
  {
    id: 'assignment',
    label: 'general.nav.assignmentSheets',
    icon: 'mdi-file-document-edit',
    route: { name: 'assignmentSheet' },
    restricted: [],
    hasChildren: false,
  },
  // schedule routes 
  {
    id: 'schedule',
    label: 'general.nav.schedules',
    icon: 'mdi-calendar-month-outline',
    route: { name: 'schedule' },
    restricted: [],
    hasChildren: false,
  },
];
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      navLeftMenus,
    };
  },
  computed: {
    drawerOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    memberRole: function() {
      //gets the role of the current logging in member
      return this.$store.state.auth.info.role;
    },
  },
  created() {},
};
</script>
